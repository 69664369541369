html {
  cursor: none;
}

button {
  cursor: none;
}

a {
  cursor: none;
}
